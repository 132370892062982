import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/router'
import { authRequiredRoutes, authRoutes } from '@/constants'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: () => {
    // always scroll to top
    window.scrollTo(0, 0)
  },
})

router.beforeEach((to: { name: any; path: string }, from, next) => {
  const token = localStorage.getItem('access')

  // If logged in, or going to the Login page.
  if (token && authRoutes.includes(to.name)) {
    // If loggedIn user and access auth pages, redirect to home page
    next({ name: 'Home' })
  }
  if (token || !authRequiredRoutes.includes(to.path)) {
    // Continue to page.
    next()
  } else {
    // Not logged in, redirect to login.
    next({ name: 'Login' })
  }
})

export default router
