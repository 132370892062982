import { createApp } from 'vue'
import App from '@/App.vue'
import '@/assets/scss/theme.scss'
import router from '@/router'
import { createPinia } from 'pinia'
import '@/plugins/vee-validate'
import * as Sentry from '@sentry/vue'

const store = createPinia()
const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [import.meta.env.VITE_APP_URL, /^\//],
    }),
  ],
  // debug: true,
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true,
    timeout: 500, // default 2000
    hooks: ['mount', 'update'],
  },
  logErrors: true,
  // attachProps: true,
  // attachStacktrace: true,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  beforeSend(event, hint) {
    // Ignore localhost error reporting
    if (window.location.hostname === 'localhost') {
      return null
    }

    // for example, not send when error code 404 when using axios
    const response: any = hint.originalException
    if (response?.response?.status === 404 || response?.name === 'AxiosError') {
      return null
    }

    // Check if it is an exception, and if so, log it.
    if (event.exception) {
      // eslint-disable-next-line no-console
      console.error(
        `[Exception handled by Sentry]: (${hint.originalException})`,
        { event, hint }
      )
    }

    // Continue sending to Sentry
    return event
  },
})

app.use(store)
app.use(router)
app.mount('#app')
