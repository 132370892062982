<script setup lang="ts">
import { ref, computed, reactive, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  imagePreview,
  imageUploadOnS3,
  get,
  clearAll,
  preventTabKey,
} from '@/helpers'
import { navigationList, enablePremiumFeature } from '@/constants'
import { BaseIcon, BaseButton } from '@/components/common'
import { IUser } from '@/interface'
import {
  mdiCrownOutline,
  mdiClipboardAccountOutline,
  mdiLockOutline,
  mdiPower,
  mdiMenu,
  mdiClose,
  mdiArrowLeft,
} from '@mdi/js'
import useStore from '@/stores'

const route = useRoute()
const router = useRouter()
const { previewProfileImages } = imageUploadOnS3()
const store = useStore()

const isMobileMenu = ref(false)
const isSubmenuOpen = ref(false)
const userDetail: IUser = reactive({
  email: '',
  firstName: '',
  lastName: '',
  profileImage: '',
})

const loggedInUserDetail = reactive({
  email: '',
  fullName: '',
  profile: '',
})

watch(userDetail, () => {
  loggedInUserDetail.email = userDetail.email
  loggedInUserDetail.fullName = `${userDetail.firstName} ${userDetail.lastName}`
  loggedInUserDetail.profile = userDetail.profileImage
})

onMounted(() => {
  setInterval(() => {
    if (localStorage.getItem('authenticated'))
      Object.assign(
        userDetail,
        JSON.parse(localStorage.getItem('authenticated') ?? '')
      )
  }, 1000)
})

const active = computed(() => route.path)

const isLogin = () => {
  if (get('access')) return true
}
const logout = async () => {
  isSubmenuOpen.value = false
  clearAll()
  await router.push('/login')
}

const unsetScroll = () => {
  document.body.style.overflow = 'unset'
  document.removeEventListener('keydown', preventTabKey)
  store.hideErrorPopup()
}
</script>

<template>
  <nav
    class="fixed flex w-full border-b items-center justify-between flex-wrap m-auto top-0 z-20 h-[80px] bg-gradient-to-b from-theme-white to-theme-white shadow-header"
  >
    <div
      class="container pl-2 pr-4 landscape:pl-4 md:px-8 lg:px-20 xl:px-15 2xl:px-20 mx-auto md:flex md:items-center max-w-[1920px]"
      :class="isMobileMenu ? 'bg-white shadow-lg pt-[1.1rem]' : ''"
    >
      <div class="flex justify-between items-center" @click="unsetScroll()">
        <a
          @click="$router.push('/')"
          class="font-bold text-xl text-primary cursor-pointer"
        >
          <img
            :src="imagePreview('valor/valor_inline_primary.svg')"
            class="mr-3 h-6 sm:h-9 w-60 lg:w-full"
            alt="image"
          />
        </a>
        <BaseButton
          btn-class="mobile-menu-btn"
          @click="isMobileMenu = !isMobileMenu"
        >
          <BaseIcon
            :height="24"
            :width="24"
            :viewBox="'0 0 24 24'"
            :path="!isMobileMenu ? mdiMenu : mdiClose"
          />
        </BaseButton>
      </div>

      <div
        class="md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0"
        :class="isMobileMenu ? 'flex' : 'hidden'"
        @click="unsetScroll()"
      >
        <div
          v-for="navigation of navigationList"
          class="self-center"
          :class="
            isMobileMenu
              ? 'p-3 text-center'
              : 'p-2 cursor-pointer hover:bg-gray-200'
          "
          @click="$router.push(navigation.redirectTo)"
        >
          <router-link
            :to="navigation.redirectTo"
            class="lg:px-1 rounded transition-colors duration-300 text-base md:text-sm lg:text-lg"
            :class="[
              active === navigation.redirectTo
                ? 'text-primary font-extrabold'
                : 'text-black font-medium',
            ]"
            @click="isMobileMenu = false"
          >
            {{ navigation.label }}
          </router-link>
        </div>

        <div
          class="text-center lg:text-left cursor-pointer mx-auto my-2 lg:my-0"
        >
          <BaseButton
            v-if="!isLogin()"
            btn-class="header-login-btn"
            :class="isMobileMenu ? 'my-4' : 'ml-6'"
            @click="$router.push('/login'), (isMobileMenu = false)"
            >Login</BaseButton
          >

          <div v-else>
            <div
              class="flex items-center ml-3"
              @click="isSubmenuOpen = !isSubmenuOpen"
            >
              <img
                class="w-10 h-10 rounded-full"
                :src="previewProfileImages(loggedInUserDetail?.profile)"
                alt="user photo"
              />
              <svg
                class="w-4 h-4 mx-1.5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>

            <div
              v-if="isSubmenuOpen"
              class="fixed inset-0 w-full h-screen z-1"
              @click="isSubmenuOpen = false"
            ></div>

            <div
              v-if="isSubmenuOpen"
              class="z-10 bg-light4 divide-y divide-gray-100 rounded relative max-w-[1920px]"
            >
              <div
                class="absolute z-30 bg-white border drop-shadow-lg text-left top-6 -right-24 md:right-1 w-64 landscape:w-screen landscape:-top-[19.7rem] landscape:-left-[17.7rem] landscape:md:top-6 landscape:md:right-1 landscape:md:w-auto"
              >
                <div class="portrait:hidden landscape:md:hidden">
                  <div class="p-1 text-base font-medium text-dark1">
                    <a
                      class="block p-2 hover:bg-gray-100"
                      @click="isSubmenuOpen = false"
                    >
                      <BaseIcon :height="20" :width="20" :path="mdiArrowLeft" />
                      Back</a
                    >
                  </div>
                  <div class="divide-y border-t border-t-gray-300"></div>
                </div>

                <div class="flex px-4 py-3 cursor-default">
                  <img
                    class="w-12 h-12 rounded-full"
                    :src="previewProfileImages(loggedInUserDetail?.profile)"
                    alt="user photo"
                  />
                  <div class="px-4 text-base font-medium text-dark1">
                    <div>
                      {{
                        loggedInUserDetail?.fullName
                          ? loggedInUserDetail?.fullName
                          : ''
                      }}
                    </div>
                    <div class="text-sm font-normal truncate">
                      {{ userDetail?.email }}
                    </div>
                  </div>
                </div>

                <div
                  class="divide-y border-t mx-4 mb-1 border-t-gray-300"
                ></div>

                <ul
                  class="pl-4 pr-1 text-base font-medium text-dark1"
                  @click=";(isSubmenuOpen = false), (isMobileMenu = false)"
                >
                  <li v-if="enablePremiumFeature">
                    <a
                      class="block px-2 py-2 hover:bg-gray-100"
                      @click="$router.push('/subscription-history')"
                    >
                      <BaseIcon
                        :height="20"
                        :width="20"
                        :path="mdiCrownOutline"
                      />
                      Subscription History</a
                    >
                  </li>
                  <li>
                    <a
                      class="block px-2 py-2 hover:bg-gray-100"
                      @click="$router.push('/my-profile')"
                    >
                      <BaseIcon
                        :height="20"
                        :width="20"
                        :path="mdiClipboardAccountOutline"
                      />
                      My Profile</a
                    >
                  </li>
                  <li>
                    <a
                      class="block px-2 py-2 hover:bg-gray-100"
                      @click="$router.push('/change-password')"
                    >
                      <BaseIcon
                        :height="20"
                        :width="20"
                        :path="mdiLockOutline"
                      />
                      Change Password</a
                    >
                  </li>
                </ul>

                <div
                  class="divide-y border-t mx-4 mt-1 border-t-gray-300"
                ></div>

                <div class="pl-4 pr-1 py-1 text-base font-medium text-dark1">
                  <a
                    class="block px-2 py-2 hover:bg-gray-100"
                    @click="logout(), (isMobileMenu = false)"
                  >
                    <BaseIcon :height="20" :width="20" :path="mdiPower" />
                    Logout</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
