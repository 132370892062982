import { reactive } from 'vue'
import { imagePreview } from '@/helpers'

export const companyName = import.meta.env.VITE_COMPANY_NAME

export const environment = import.meta.env.VITE_ENV

export const gender = [
  { id: 1, name: 'Male' },
  { id: 2, name: 'Female' },
]

export const equityExchange = [
  { id: 'NIFTY_50', name: 'NIFTY 50' },
  { id: 'BANK_NIFTY', name: 'BANK NIFTY' },
  { id: 'FINNIFTY', name: 'FIN NIFTY' },
  { id: 'MIDCPNIFTY', name: 'MIDCP NIFTY' },
  { id: 'EQUITY_OTHERS', name: 'OTHERS' },
]

export const currencyExchange = [
  { id: 'USD_INR', name: 'USD - INR' },
  { id: 'EURO_INR', name: 'EURO - INR' },
  { id: 'GB_POUND_INR', name: 'GBP - INR' },
  { id: 'CURRENCY_OTHERS', name: 'OTHERS' },
]

export const symmetricalIndexes = [
  { id: 'NIFTY_50', name: 'NIFTY 50' },
  { id: 'BANK_NIFTY', name: 'BANK NIFTY' },
  { id: 'FINNIFTY', name: 'FIN NIFTY' },
  { id: 'MIDCPNIFTY', name: 'MIDCP NIFTY' },
]

export const strategyIndexes = reactive([
  {
    id: 'NIFTY_50',
    name: 'NIFTY 50',
    type: 'NIFTY50',
    indexState: true,
    message: '',
  },
  {
    id: 'BANK_NIFTY',
    name: 'BANK NIFTY',
    type: 'BANKNIFTY',
    indexState: true,
    message: '',
  },
  {
    id: 'FINNIFTY',
    name: 'FIN NIFTY',
    type: 'FINNIFTY',
    indexState: true,
    message: '',
  },
  {
    id: 'MIDCPNIFTY',
    name: 'MIDCP NIFTY',
    type: 'MIDCPNIFTY',
    indexState: true,
    message: '',
  },
])

export const scatteredStrategyResultOptions = [
  'optionContractOne',
  'optionContractTwo',
  'optionContractThree',
]

export const occupation = [
  { id: 1, name: 'Public Sector' },
  { id: 2, name: 'Private Sector' },
  { id: 3, name: 'Government Service' },
  { id: 4, name: 'Business' },
  { id: 5, name: 'Professional' },
  { id: 6, name: 'Agriculturist' },
  { id: 7, name: 'Retired' },
  { id: 8, name: 'Housewife' },
  { id: 9, name: 'Student' },
  { id: 10, name: 'Others' },
]

export const imageResolution = {
  homePageCalculators: '?h=192&w=192',
  calculatorDetailPage: '?h=220&w=220',
  calculatorLists: '?h=200&w=200',
}

export const calculatorsSlug = {
  equity: 'equity-options-calculator',
  currency: 'currency-options-calculator',
}

export const disclaimer = {
  futuresCalculator:
    "Ideally, Future's Price is calculated on the basis of T-bills growth or interest rate. Hence, this calculator will always show some ideal growth ignoring the down fall under some circumstances. It does not mean that underlying stock's price will rise only.",
  symmetricalCalculator:
    'Trading with reflected strategy outputs will result in profit regardless of the direction (Rise/Fall) that underlying stock/index (Instrument) may follow. It may end up with some reduced and compensated loss in uncontrolled & hyped market situations and in cases where considerable fluctuation in the price of the underlying instrument is not seen. The onus of ending a trade with profit or loss sheerly lies with you. Remember! Option trading is always subject to market risk. Kindly, do trade at your own risk. Have a happy trading. Thank you!',
}

export const otherCurrency = ['EQUITY_OTHERS', 'CURRENCY_OTHERS']

export const slidesData = [
  {
    name: 'slide_1',
    title: 'Reliable Strategies',
    description:
      'You are just one step away from one of the best strategies that would assist you in getting into profitable trades. We want to make you feel as though earning money is as simple as play of your fingertips.',
    isRightAlignment: false,
    logo: imagePreview('home/slide_bg_1.svg'),
    image: imagePreview('home/slide_1.svg'),
    textColor: 'black',
    link: '',
  },
  {
    name: 'slide_2',
    title: 'Market Analysis',
    description:
      'Strategies and calculators displayed herewith, are the results of lots of background data analysis. We present apt analysis on market performance so that you stay informed and prepared for current trends.',
    isRightAlignment: true,
    logo: imagePreview('home/slide_bg_2.svg'),
    image: imagePreview('home/slide_2.svg'),
    textColor: 'black',
    link: '#',
  },
  {
    name: 'slide_3',
    title: 'Classic Calculators',
    description:
      'Get access to our calculators which would make you better equipped to decide on your trades. Calculators that are incorporated herein, consider current standard deviations, time to expiry, free interest rates, dividend yield and many other factors to deliver outputs.',
    isRightAlignment: false,
    logo: imagePreview('home/slide_bg_3.svg'),
    image: imagePreview('home/slide_3.svg'),
    textColor: 'black',
    link: '#',
  },
]

export const authRequiredRoutes = [
  '/my-profile',
  '/change-password',
  '/subscription-history',
]

export const authRoutes = [
  'Login',
  'Register',
  'OtpVerification',
  'LoginWithOTP',
  'ForgotPassword',
]

export const subscriberDateFormat = 'DD MMMM YYYY'

export const indianDateFormat = 'DD-MM-YYYY'

export const strategyDateFormat = 'Do-MMM'

export const scatteredPositions = {
  bullish: 'BULLISH',
  bearish: 'BEARISH',
}

export enum Subscription {
  SUBSCRIBED = 'Subscribed',
  NOT_SUBSCRIBED = 'NotSubscribed',
}

export enum isActivePlan {
  ACTIVE = 'Active',
  NOT_ACTIVE = 'NotActive',
}

export const valorLogoRazorPay = new URL(
  '@/assets/images/valor/valor_icon.svg',
  import.meta.url
).href

export const originURL = window.location.origin.toString()

export const redirectToLogin = [
  'Token is invalid',
  'Authentication token is expired. Kindly login again.',
]

export const labelErrorsRoute = [
  '/auth/login/passwordless/verify',
  '/auth/login',
  '/auth/password/forget/verify',
  '/auth/register',
  '/account/change/email/verify',
  '/account/change/password',
  '/account/change/email',
]

export const enablePremiumFeature = false

export enum appEnvironment {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}
