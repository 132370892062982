<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useStore from '@/stores'
import { mdiAlertCircleOutline } from '@mdi/js'
import { texts } from '@/constants'

const { isUnknownError, unknownErrorMessage } = storeToRefs(useStore())
const store = useStore()

const closeModal = () => store.hideErrorPopup()
</script>

<template>
  <transition name="fade">
    <div v-if="isUnknownError" id="show-popup">
      <div class="popup-container inherit-popup">
        <div class="popup-center">
          <div class="w-full max-w-md p-3 mx-4 shadow-lg bg-white">
            <div class="flex justify-end items-center px-2 text-xl">
              <button type="button" id="popup-close" @click="closeModal()">
                ✖
              </button>
            </div>
            <div
              class="py-3 px-6 flex-auto justify-center text-center leading-6"
            >
              <BaseIcon
                :path="mdiAlertCircleOutline"
                :width="60"
                :height="60"
                class="text-red-600 mb-4"
              />
              <p class="text-lg text-gray-500 font-bold">
                {{
                  unknownErrorMessage
                    ? unknownErrorMessage
                    : texts.something_went_wrong
                }}
              </p>
            </div>

            <div class="p-3 mt-1 text-center space-x-4 md:block">
              <button
                id="popup-submit"
                class="mb-2 md:mb-0 bg-theme-orange px-5 py-2 text-md shadow-sm font-medium tracking-wider text-white rounded-md uppercase"
                @click="closeModal()"
              >
                ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
