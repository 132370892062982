import axios from 'axios'
import defaultImage from '@/assets/images/profile/default_profile.svg'
const s3Url = import.meta.env.VITE_S3_URL
const cloudfrontURL = import.meta.env.VITE_CLOUDFRONT_URL

export const imageUploadOnS3 = () => {
  const fileUpload = async (binaryImage: File) => {
    const mimeType = binaryImage?.type
    const imageName = `${Date.now().toString()}.${mimeType.split('/').pop()}`

    try {
      const res: any = await axios.get(s3Url, {
        params: {
          filename: imageName,
          fileType: mimeType,
          token: localStorage.getItem('access'),
        },
      })
      if (res.data.signedRequest) {
        const signedRequest = res.data.signedRequest

        const options: any = {
          headers: {
            'Content-Type': mimeType,
          },
        }
        await axios.put(signedRequest, binaryImage, options)

        return imageName
      }
    } catch (err) {
      console.log(err)
    }
  }

  const previewProfileImages = (imageName: string | undefined) => {
    if (!imageName) return defaultImage
    if (imageName && imageName !== undefined)
      return `${cloudfrontURL}/${imageName}`
  }

  return { fileUpload, previewProfileImages }
}
