import { ICalculatorDetails } from '@/interface'

export const socialMediaIcons = [
  {
    name: 'Facebook',
    logo: new URL('@/assets/images/social/facebook_logo.svg', import.meta.url)
      .href,
  },
  {
    name: 'Instagram',
    logo: new URL('@/assets/images/social/instagram_logo.svg', import.meta.url)
      .href,
  },
  {
    name: 'Twitter',
    logo: new URL('@/assets/images/social/twitter_logo.svg', import.meta.url)
      .href,
  },
  {
    name: 'Linkdin',
    logo: new URL('@/assets/images/social/linkedIn_logo.svg', import.meta.url)
      .href,
  },
]

export const footerLogo = new URL(
  '@/assets/images/valor/valor_inline_white.svg',
  import.meta.url
).href

export const calculators: ICalculatorDetails[] = [
  {
    label: 'Equity Options Calculator',
    slug: 'equity-options-calculator',
  },
  {
    label: 'Currency Options Calculator',
    slug: 'currency-options-calculator',
  },
  {
    label: 'Futures Calculator',
    slug: 'futures-calculator',
  },
]

export const strategies: ICalculatorDetails[] = [
  {
    label: 'Symmetrical Strategy',
    slug: 'symmetrical-strategy',
  },
  {
    label: 'Scattered Strategy',
    slug: 'scattered-strategy',
  },
]

export const allCalc = calculators.concat(strategies)

export const userGuidelines: ICalculatorDetails[] = [
  {
    slug: 'symmetrical-user-guidelines',
  },
  {
    slug: 'scattered-user-guidelines',
  },
]

export const pages = [
  {
    label: 'About Us',
    href: '/about-us',
  },
  {
    label: 'Contact Us',
    href: '/contact-us',
  },
  // feature hide for sometime
  // {
  //   label: 'Subscriptions',
  //   href: '/subscription-plans',
  // },
  {
    label: 'User Guidelines',
    href: '/user-guideline',
  },
  {
    label: 'Terms & Conditions',
    href: '/terms-conditions',
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy',
  },
]

export const copyright = `All rights reserved. Made in India with pride`
