import { defineStore } from 'pinia'
import router from '@/router'
import { redirectToLogin } from '@/constants/common'

const useStore = defineStore('init-store', {
  state: (): {
    isLoading: boolean
    authenticated: any
    isUnknownError: boolean
    unknownErrorMessage: string
  } => {
    return {
      isLoading: true,
      authenticated: localStorage.getItem('authenticated'),
      isUnknownError: false,
      unknownErrorMessage: '',
    }
  },

  getters: {},

  actions: {
    showLoading() {
      this.isLoading = true
    },
    hideLoading() {
      // store.$patch({ isLoading: false }) - alternative without actions
      this.isLoading = false
    },

    setAuthenticated(payload: string) {
      this.authenticated = localStorage.setItem('authenticated', payload)
    },

    showErrorPopup(value: string) {
      window.scrollTo(0, 0)
      this.isUnknownError = true
      this.unknownErrorMessage = value
      document.body.style.overflow = 'hidden'
    },
    async hideErrorPopup() {
      if (redirectToLogin.includes(this.unknownErrorMessage)) {
        localStorage.clear()
        await router.push('/login')
      }
      this.isUnknownError = false
      this.unknownErrorMessage = ''
      document.body.style.overflow = 'unset'
    },
  },
})

export default useStore
