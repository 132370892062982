import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc)
dayjs.extend(advancedFormat) // 'Q Do k kk X x'

export const currentDate = dayjs()

export const currentUtcDate = dayjs.utc().format()

export const formatDate = (value: string, format = 'YYYY-MM-DD') => {
  return dayjs(value).format(format)
}

export const aboveAge = (value = currentDate) => {
  return value.subtract(16, 'year').toDate()
}

export const calcDateLimit = () => {
  return {
    lowerDate: currentDate.add(1, 'day').toDate(),
    upperDateFutures: currentDate.add(1, 'year').toDate(),
    upperDateOptions: currentDate.add(2, 'month').toDate(),
  }
}

export const dateUTCToLocal = (value: any, format = 'YYYY-MM-DD') => {
  return dayjs.utc(value).local().format(format)
}

export const dateUTCFormat = (value: any) => {
  return dayjs(value).utc().format()
}

export const isInBetweenWeek = (value: any) => {
  const date = currentDate.add(7, 'day').toDate()
  const selectedDate = dayjs(value).toDate()

  return date < selectedDate
}

export const dayLeft = (val: string) => {
  const futureDate = dayjs.utc(val).local()
  const today = dayjs().utc().local()

  return futureDate.diff(today, 'day')
}

export const totalDays = (date1: string, date2: string) => {
  return dayjs(date1).diff(dayjs(date2), 'day')
}

export const diffMinute = (date1: string, date2: string) => {
  return dayjs(date1).diff(dayjs(date2), 'minute')
}

export const currentYear = () => dayjs().year()
