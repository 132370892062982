export const navigationList = [
  {
    label: 'Home',
    redirectTo: '/',
  },
  {
    label: 'About Us',
    redirectTo: '/about-us',
  },
  // feature hide for sometime
  // {
  //   label: 'Subscriptions',
  //   redirectTo: '/subscription-plans',
  // },
  {
    label: 'Contact Us',
    redirectTo: '/contact-us',
  },
]
