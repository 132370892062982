export const texts = {
  something_went_wrong: 'Something went wrong!',
  subscribe_to_premium:
    'Please Login/Signup and subscribe to your premium account',
  paid_calc_text1: 'Strategy Unavailable Currently',
  paid_calc_text2:
    'This strategy is available from 10 AM to 2:30 PM during Market hours',
  paid_calc_text3: 'Available next on',
  paid_calc_note:
    'THIS PREMIUM CALCULATOR WILL ONLY BE ACTIVE FROM 10:00 AM TO 2:30 PM DURING MARKET HOURS.',
  password_changed: 'Your password has been changed',
  four_digit_code: 'Enter 6-digit verification code sent to your email',
  five_days_trial: `You've exhausted your 5 Days Free Trial for using Premium Calculators. To keep availing the benefits, subscribe to Premium.`,
  user_unverified: 'User unverified',
}
