import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/Register.vue'),
  },
  {
    path: '/otp-verification',
    name: 'OtpVerification',
    component: () => import('@/views/register/OtpVerification.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
  },
  {
    path: '/otp-login',
    name: 'LoginWithOTP',
    component: () => import('@/views/login/LoginWithOTP.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/login/ForgotPassword.vue'),
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: () => import('@/views/profile/MyProfile.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/profile/ChangePassword.vue'),
  },
  {
    path: '/calculator/:type',
    name: 'Calculator',
    component: () => import('@/views/calculator/Index.vue'),
    props: true,
  },
  {
    path: '/user-guideline',
    name: 'UserGuideline',
    component: () => import('@/views/user-guideline/Index.vue'),
  },
  {
    path: '/user-guideline/details/:slug',
    name: 'UserGuidelineDetail',
    component: () => import('@/views/user-guideline/Detail.vue'),
    props: true,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/views/cms-pages/AboutUs.vue'),
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: () => import('@/views/cms-pages/TermsConditions.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/cms-pages/PrivacyPolicy.vue'),
  },
  {
    path: '/subscription-plans',
    name: 'SubscriptionPlans',
    component: () => import('@/views/subscription/Subscription.vue'),
  },
  {
    path: '/subscription-history',
    name: 'SubscriptionHistory',
    component: () => import('@/views/subscription/SubscriptionHistory.vue'),
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('@/views/contact-us/ContactUs.vue'),
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: () => import('@/views/payments/ThankYou.vue'),
  },
  {
    path: '/home',
    redirect: '/',
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: () => import('@/views/error/PageNotFound.vue'),
  },
]

export default routes
