export const fixedDecimalNumber = (value: number, decimal = 5) => {
  return value.toFixed(decimal)
}

export const reset = (value: Object, resetData: Object) => {
  return Object.assign(value, resetData)
}

export const titleCase = (val: string) => {
  if (val) return val.replace(/[-_]+(.)/g, (_, c: string) => ' ' + c)
}

export const decodeContent = (val: string | undefined) => {
  if (val) return decodeURIComponent(escape(atob(val)))
}

export const imagePreview = (val: string) => {
  if (val) return new URL(`/src/assets/images/${val}`, import.meta.url).href
}

export const removeCountryCode = (val: string) => {
  if (val) return val.replace(/\D/g, '').slice(-10)
}

export const staredMobile = (
  value: string,
  prefixLength = 2,
  suffixLength = 3
) => {
  const regExp = new RegExp(
    '^(\\+?\\d{' + prefixLength + '})(\\d+)(\\d{' + suffixLength + '})$'
  )

  return value.replace(regExp, (match, prefix, middle, suffix) => {
    return prefix + '*'.repeat(middle.length) + suffix
  })
}

export const arrayFindWithAssign = (arr: any, data: any) => {
  for (const item of arr) {
    const result = data.find((val: any) => val.slug === item.slug)
    Object.assign(item, result)
  }
}

export const preventTabKey = (e: any) => {
  if (e.keyCode === 9) e.preventDefault() // Tab key
}
