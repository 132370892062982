import { defineRule, configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import {
  required,
  alpha,
  numeric,
  length,
  email,
  confirmed,
  min,
  digits,
  min_value,
  between,
  max,
} from '@vee-validate/rules'

defineRule('required', required)
defineRule('name_required', required)
defineRule('number_required', required)
defineRule('password_field_required', required)
defineRule('confirm_required', required)
defineRule('date_required', required)
defineRule('select_required', required)
defineRule('email_required', required)
defineRule('otp_required', required)
defineRule('password_required', required)
defineRule('email', email)
defineRule('alpha', alpha)
defineRule('numeric', numeric)
defineRule('length', length)
defineRule('pin_length', digits)
defineRule('confirmed', confirmed)
defineRule('min', min)
defineRule('min_password', min)
defineRule('max_password', max)
defineRule('valid_phone', length)
defineRule('min_value', min_value)
defineRule('min_name', min)
defineRule('between', between)

defineRule('regex_password', (value: string) => {
  // Check if valid password
  // eslint-disable-next-line no-useless-escape
  if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])/.test(value)) {
    return 'password must be 8-15 characters, contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character'
  }
  return true
})

defineRule('regex_price', (value: string) => {
  if (!/^\d\d{0,9}(\.\d{1,5})?%?$/.test(value)) {
    return 'enter numeric or decimal value only'
  }
  return true
})

defineRule('vselect', (value: any) => {
  // return false if enter value doesn't match with options for v-select
  return !(
    value !== undefined &&
    value !== null &&
    Object.keys(value).length > 0
  )
})

defineRule('restrict_zero', (value: string) => {
  if (/^-?0*(\.0+)?$/.test(value)) {
    // check zero with decimal and negative
    return 'Please enter value greater then 0'
  }
  return true
})

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: false,
  validateOnModelUpdate: true,

  // Generates an English message locale generator
  generateMessage: localize('en', {
    messages: {
      required: 'enter a {field}',
      password_required: 'enter a password',
      password_field_required: 'enter a {field} password',
      confirm_required: 'enter {field} password',
      name_required: 'enter {field} name',
      number_required: 'enter {field} no.',
      date_required: 'select {field}',
      select_required: 'select {field}',
      email_required: 'enter {field} address',
      otp_required: 'enter OTP',
      alpha: 'enter alphabet characters only',
      email: 'enter valid {field} address',
      numeric: 'enter numeric value only',
      length: '{field} number must be of 10 digits',
      pin_length: '{field} code must be 6 digits',
      confirmed: 'confirm password does not match with new password',
      min: '{field} length must be 0:{min} characters.',
      regex_password:
        'password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
      min_password: 'password must be atleast 0:{min} characters',
      max_password: 'password should be of 0:{max} characters',
      valid_phone: 'enter valid mobile no.',
      vselect: 'Please select {field}',
      min_value: '{field} must be greater than 1050',
      min_name: '{field} name length must be 0:{min} characters.',
      between: '{field} should be 0:{min} to 1:{max}.',
    },
  }),
})
