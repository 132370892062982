<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useStore from '@/stores'
import { imagePreview } from '@/helpers'

const { isLoading } = storeToRefs(useStore())
</script>

<template>
  <div
    v-if="isLoading"
    class="v-loader w-full z-40 overflow-hidden flex flex-col items-center justify-center mt-20 bg-light1"
  >
    <div class="h-24 w-24">
      <img
        :src="imagePreview('valor/loader.gif')"
        class="w-full lg:w-auto h-auto lg:-mt-0"
        alt="Sample image"
      />
    </div>
  </div>
</template>
<style lang="scss">
.v-loader {
  height: calc(100vh - 449px);
}
</style>
