export const set = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

export const get = (key: string): string | null => {
  return localStorage.getItem(key)
}

export const remove = (key: string) => {
  localStorage.removeItem(key)
}

export const clearAll = () => {
  localStorage.clear()
}
